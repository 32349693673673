import React, { useEffect, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Grid from "./grid"
import InlineVideo from "./inline-video"
import useVideoDomain from "../hooks/use-video-domain"

const PersonMedia = ({person, highlightPerson, version}) => {
  const parentEl = useRef()
  const videoEl = useRef()
  const images = person.assets.filter(item => item.src !== null)
  const videos = person.assets.filter(item => item.video !== null)
  const numOfImages = images.length
  const numOfVideos = videos.length
  const domain = useVideoDomain()
  const margins = [ 'lg:-mt-10', 'lg:-mt-16', 'lg:-mt-20', 'lg:-mt-24', 'lg:mt-10', 'lg:mt-16', 'lg:mt-20', 'lg:mt-24']
  const getRandom = (max) => {
    return Math.floor(Math.random() * max) + 1
  }
  const stopStreamedVideo = (videoElem) => {
    videoElem.pause()
    videoElem.src = ``
    videoElem.load()
  }
  useEffect(() => {
    videoEl.current = [...parentEl.current.getElementsByTagName(`video`)]
    return () => {
      if (videoEl.current.length > 0) stopStreamedVideo(videoEl.current[0])
    }
  }, [])
  const Image = ({ image }) => {
    return (
      <div className={`w-full lg:flex lg:justify-center lg:items-center`}>
        <GatsbyImage
          image={getImage(image)}
          alt={``}
        />
      </div>
    )
  }
  const Video = ({ video }) => {
    return (
      <div className={`w-full lg:flex lg:justify-center lg:items-center`}>
        <InlineVideo
          version={version}
          src={video}
          basepath={domain}
        />
      </div>
    )
  }
  const OneImageOneVideo = () => {
    const rndInt = getRandom(2)
    const layout = rndInt === 2 ?
      [`col-start-1 col-end-13 lg:col-start-2 lg:col-end-6 lg:mt-20`, `col-start-1 col-end-13 lg:col-start-7 lg:col-end-13`] : [`col-start-1 col-end-13 lg:col-start-2 lg:col-end-6`, `col-start-1 col-end-13 lg:col-start-7 lg:col-end-12 lg:mt-60`]
    const image = images[0].src
    const video = videos[0].video
    return (
      <>
        <div className={`${layout[0]}`}>
          <Image image={image} />
        </div>
        <div className={`${layout[1]}`}>
          <Video video={video} />
        </div>
      </>
    )
  }
  const ThreeImages = () => {
    const layout = [
      `col-start-1 col-end-13 lg:col-start-1 lg:col-end-5 lg:px-10 ${margins[getRandom(margins.length)]}`,
      `col-start-1 col-end-13 lg:col-start-5 lg:col-end-9 lg:px-6 ${margins[getRandom(margins.length)]}`,
      `col-start-1 col-end-13 lg:col-start-9 lg:col-end-13 lg:px-12 ${margins[getRandom(margins.length)]}`
    ]
    return (
      <>
        <div className={`${layout[0]}`}>
          <Image image={images[0].src} />
        </div>
        <div className={`${layout[1]}`}>
          <Image image={images[1].src} />
        </div>
        <div className={`${layout[2]}`}>
          <Image image={images[2].src} />
        </div>
      </>
    )
  }
  const TwoImagesOneVideo = () => {
    const layout = [
      `col-start-1 col-end-13 lg:col-start-1 lg:col-end-5 lg:px-10 ${margins[getRandom(margins.length)]}`,
      `col-start-1 col-end-13 lg:col-start-5 lg:col-end-9 lg:px-6 ${margins[getRandom(margins.length)]}`,
      `col-start-1 col-end-13 lg:col-start-9 lg:col-end-13 lg:px-12 ${margins[getRandom(margins.length)]}`
    ]
    return (
      <>
        <div className={`${layout[0]}`}>
          <Image image={images[0].src} />
        </div>
        <div className={`${layout[1]}`}>
          <Video video={videos[0].video} />
        </div>
        <div className={`${layout[2]}`}>
          <Image image={images[1].src} />
        </div>
      </>
    )
  }
  return (
    <div ref={parentEl} className={`hidden lg:block absolute w-screen h-full max-w-[1920px] overflow-hidden`}>
      { (highlightPerson && highlightPerson.id === person.id) &&
      <div style={{ marginTop: `${highlightPerson?.position}px` }} className={`relative`}>
        <Grid className={`w-full h-full`}>
          { (numOfImages === 1 && numOfVideos === 1) && <OneImageOneVideo /> }
          { (numOfImages === 3 && numOfVideos === 0) && <ThreeImages /> }
          { (numOfImages === 2 && numOfVideos === 1) && <TwoImagesOneVideo /> }
        </Grid>
      </div>
      }
    </div>
  )
}

export default PersonMedia
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Grid from "./grid"
import useVideoDomain from "../hooks/use-video-domain"
import InlineVideo from "./inline-video"
import { XIcon } from '@heroicons/react/outline'

const PersonFullVideo = ({ person, onClose }) => {
  const domain = useVideoDomain()
  const heroVideo = person.assets.filter(item => item.video)
  const allImages = person.assets.filter(item => item.src)
  const newAssets = [
    ...heroVideo,
    ...allImages,
  ]
  const assets = newAssets.map((item, index) => {
    const image = item?.src
    const video = item?.video
    return (
      <div key={`${person.id}-asset-${index}`} className={`w-full flex justify-center items-center lg:min-h-[600px]`}>
        { video &&
          <InlineVideo
            src={video}
            basepath={domain}
            muted={false}
            controls
            className={`w-full`}
          />
        }
        { image &&
          <GatsbyImage
            image={getImage(image)}
            alt={``}
          />
        }
      </div>
    )
  })
  return (
    <div className={`w-screen h-screen fixed top-0 left-0 z-[99999] text-[22px] lg:text-[30px] lg:font-bold text-[#1A2C39]`}>
      <div className={`w-full h-full absolute z-10 overflow-y-auto`}>
        <div className={`flex w-full h-full max-w-[1920px] mx-auto`}>
          <Grid className={`w-full`}>
            <div className={`col-start-2 col-end-12 py-20 lg:py-40`}>
              <div className={`flex flex-col space-y-4 lg:space-y-10`}>
                <div className={`lg:flex lg:justify-between lg:pb-4`}>
                  <div>{person.name}</div>
                  <div className={`opacity-80 lg:opacity-100`}>{person.position}</div>
                </div>
                { assets }
              </div>
            </div>
          </Grid>
        </div>
      </div>
      <button style={{ outline: 0 }} onClick={onClose} className={`w-14 h-14 fixed z-20 right-3 fill-[#1A2C39] p-2`}>
        <XIcon className={`w-full h-full`} />
      </button>
      <button style={{ outline: 0 }} onClick={onClose} className={`w-full h-full absolute z-0 bg-white opacity-100`}></button>
    </div>
  )
}

export default PersonFullVideo
import React, { useEffect, useRef, useState } from "react"
import constants from "../../constants"
import useIntersectionObserver from '../../hooks/use-intersection-observer'
import useDeviceVersion from '../../hooks/use-device-version'

const InlineVideo = React.forwardRef((props, ref) => {
  const hasSound = props.hasSound || false
  let base = props.basepath ? props.basepath : constants.S3
  const refToObserve = useRef()
  const [isHidden, setIsHidden] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [isMute, setIsMute] = useState(props.muted || true);
  const videoEl = useRef();
  const deviceVersion = useDeviceVersion();
  const reference = ref ? ref : videoEl
  let version = deviceVersion === constants.MOBILE ? `mobile` : `desktop`;
  if (props.version) version = props.version
  version = version + '/';

  if(props.skipBase) {
    base = '';
    version = '';
  }
  useIntersectionObserver({
    target: refToObserve,
    rootMargin: `0px 0px 0px 0px`,
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        setIsHidden(false)
        setIsPaused(false)
      } else {
        setIsPaused(true)
      }
    }
  })
  useEffect(() => {
    const vid = reference.current
    if (vid) {
      if (!isPaused) {
        const playPromise = vid.play()
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Automatic playback started!
          }).catch(() => {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          })
        }
      } else {
        vid.pause()
      }
    }
  }, [isPaused]);
  const SoundOn = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeWidth=".1"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M15,23l-9.3-6H0V7h5.7L15,1V23z M6,8v8l8,5.2V2.8C14,2.8,6,8,6,8z M20.2,3.8c2.3,2,3.8,4.9,3.8,8.2
    c0,3.3-1.4,6.2-3.8,8.2l-0.7-0.7C21.7,17.7,23,15,23,12s-1.3-5.7-3.5-7.5C19.5,4.5,20.2,3.8,20.2,3.8z M17.3,6.7
    c1.5,1.3,2.5,3.2,2.5,5.3s-1,4-2.5,5.3l-0.7-0.7c1.3-1.1,2.2-2.7,2.2-4.6c0-1.9-0.9-3.5-2.2-4.6C16.6,7.4,17.3,6.7,17.3,6.7z M5,8H1
    v8h4V8z"
      />
    </svg>
  );
  const SoundOff = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeWidth=".1"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M15,23l-9.3-6H0V7h5.7L15,1V23z M6,8v8l8,5.2V2.8L6,8z M5,8H1v8h4V8z M21.3,12l1.6-1.7L21.6,9l-1.7,1.7L18.3,9
          L17,10.3l1.6,1.7L17,13.7l1.3,1.3l1.6-1.7l1.7,1.7l1.3-1.4L21.3,12z"
      />
    </svg>
  );
  // useEffect(() => {
  //   if (props.muted === false) {
  //     reference.current.muted = false
  //   }
  // }, [])
  const onCanPlay = () => {
    window.locoScroll?.update();
  }
  return (
    <div ref={refToObserve} className={`${props.className} relative`}>
      <div className={`relative w-full h-full`}>
        {!isHidden && (
          <div className={`w-full h-full`}>
            { props.controls ?
              <video
                // className={props.className}
                ref={reference}
                playsInline
                loop
                controls
                muted={isMute}
                onCanPlay={onCanPlay}
                style={props.videoStyle}
              >
                <source src={`${base}${version}${props.src}`} type={`video/mp4`} />
              </video>
            :
              <video
                // className={props.className}
                ref={reference}
                playsInline
                loop
                muted={isMute}
                onCanPlay={onCanPlay}
                style={props.videoStyle}
              >
                <source src={`${base}${version}${props.src}`} type={`video/mp4`} />
              </video>
            }
            { hasSound && (
            <button type="button" onClick={() => setIsMute(!isMute)} className="w-1/12 h-1/12 lg:w-10 lg:h-10 absolute z-10 bottom-0 right-0 my-6 mr-6 lg:my-[20px] lg:mr-[20px]">
              { !isMute && <SoundOn /> }
              { isMute && <SoundOff /> }
            </button>
          )}
          </div>
        )}
      </div>
    </div>
  )
})

export default InlineVideo

import React from "react"

const useVideoDomain = () => {
  let domain = `https://eccocpeu.blob.core.windows.net/video/elu/`
  if (typeof window !== `undefined`) {
    if (window.location.hostname.indexOf(`cn.`) >= 0) domain = `https://eccocpcn.blob.core.chinacloudapi.cn/video/elu/`
  }
  return domain
}

export default useVideoDomain
import React from "react"

const PersonRow = ({ className, person, highlightPerson, onEnter, onLeave, onClick }) => {
  const isHighlight = (highlightPerson && highlightPerson.id === person.id)
  const tdClassName = `px-6 lg:px-10 py-3 lg:whitespace-nowrap`
  const topTitle = `absolute z-10 ${(isHighlight && highlightPerson !== undefined) ? `font-bold` : `font-medium`}`
  // const topTitle = `absolute z-10 ${false ? `font-bold` : `font-medium`}`
  const bottomTitle = `relative z-0 opacity-0 font-bold`
  const onPersonClick = (id) => {
    onClick(id)
  }
  const onPersonEnter = (id, e) => {
    const y = e.target.offsetTop * 0.5
    onEnter(id, y)
  }
  const onPersonLeave = (id) => {
    onLeave(id)
  }
  return (
    <>
      <tr
        onClick={() => { onPersonClick(person) }}
        onMouseEnter={(e) => { onPersonEnter(person.id, e) }}
        onMouseLeave={() => { onPersonLeave(person.id) }}
        className={`${className} hidden lg:table-row cursor-pointer transition-opacity ease-out duration-300 ${((isHighlight || highlightPerson === undefined)) ? `opacity-100` : `opacity-10`}`}
        // className={`${className} hidden lg:table-row cursor-pointer transition-opacity ease-out duration-300 ${false ? `opacity-100` : `opacity-10`}`}
        id={person.id}
      >
        <td className={`${tdClassName} relative`}>
          <div className={`${topTitle}`}>{person.name}</div>
          <div className={`${bottomTitle}`}>{person.name}</div>
        </td>
        <td className={`${tdClassName}`}>
          <div className={`${topTitle}`}>{person.position}</div>
          <div className={`${bottomTitle}`}>{person.position}</div>
        </td>
        <td className={`${tdClassName}`}>
          <div className={`${topTitle}`}>{person.description}</div>
          <div className={`${bottomTitle}`}>{person.description}</div>
        </td>
      </tr>
      <tr
        onClick={() => { onPersonClick(person.id) }}
        className={`${className} lg:hidden text-[22px] text-[#1A2C39]`}
        id={person.id}
      >
        <td className={`${tdClassName} relative`}>
          <div className={``}>{person.name}</div>
          <div className={`opacity-60`}>{person.position}</div>
        </td>
      </tr>
    </>
  )
}

export default PersonRow
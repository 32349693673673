import React, {useRef, useState} from "react"
import Layout from "../components/layout"
import SEO from "components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {Hand} from "../components/icons"
import Marquee from "react-fast-marquee"
import {graphql} from "gatsby"
import PersonRow from "../components/person-row"
import PersonMedia from "../components/person-media"
import Grid from "../components/grid"
import PersonFullVideo from "../components/person-full-video"

const PeopleMedia = ({people, highlightPerson}) => (
  people.map((person) => <PersonMedia key={person.id} person={person} version={`mobile`} highlightPerson={highlightPerson} />)
)

const PeopleList = ({people, setVideoPerson}) => {
  const parentEl = useRef()
  const [highlightPerson, setHighlightPerson] = useState(undefined)

  const onPersonClick = (person) => {
    // setHighlightPerson({id})
    setVideoPerson(person)
  }
  const onPersonEnter = (id, position) => {
    setHighlightPerson({id, position})
  }
  const onPersonLeave = () => {
    setHighlightPerson(undefined)
  }


  return (
    <div ref={parentEl} className="flex flex-col text-[22px] text-[#1A2C39]">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-start inline-block min-w-full">
          <div className="overflow-hidden pt-14 py-20">
            <table className="min-w-full">
              <tbody className="">
                {people.map((person) =>
                  <PersonRow
                    key={person.id}
                    person={person}
                    highlightPerson={highlightPerson}
                    onEnter={onPersonEnter}
                    onLeave={onPersonLeave}
                    onClick={onPersonClick}
                  />)
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={`absolute z-0 w-full h-full`}>
        <PeopleMedia people={people} highlightPerson={highlightPerson} />
      </div>
    </div>
  )
}

const Collage = ({location, data}) => {
  const [videoPerson, setVideoPerson] = useState(null);
  const onCloseFullVideo = () => {
    setVideoPerson(null)
  }

  const filepath = `../../static/images/collage/`
  const people = data.allCollageJson.nodes


  const text1 = `A brave new world continues. ECCO Leather Goods is back for SS22 with a functional and playful collection – for New Utopians, living in a brave new world.`
  const text2 = `This time around you can discover our collection through our series of mini collection videos: ‘Season 2 Shorts.’ All shorts scripted and performed by our team in Dongen, Netherlands - the home of ECCO Leather Goods.`

  return (
    <Layout location={location}>
      <SEO title={`Collage of Information`} pathname={location.pathname} />
      <div className={`w-full h-full`} style={{padding: '0 0 150vh'}} >
        <div className={`w-full h-[90vh] lg:h-full relative bg-black`}>
          <div className={`hidden lg:block relative w-full h-full z-0`}>
            <StaticImage
              src={`${filepath}ECCO_210709_TCRISTIANI_030_254.jpg`}
              alt={``}
              className={`w-full h-full`}
            />
          </div>
          <div className={`lg:hidden w-full h-full z-0`}>
            <StaticImage
              src={`${filepath}ECCO_210709_TCRISTIANI_030_254-mobile.jpg`}
              alt={``}
              className={`w-full h-full`}
            />
          </div>
          <div className={`absolute z-10 bottom-0 left-0 text-white w-full lg:w-7/12 pb-6 p-6 lg:p-10`}>
            <div className={`text-[38px] lg:text-[80px] leading-none mb-8`}>Season Two Shorts</div>
            <div className={`text-[22px] leading-tight w-10/12 pb-0 lg:pb-10`}>
              {text1}
              <br /><br />
              <span className={`hidden lg:block`}>{text2}</span>
            </div>
          </div>
        </div>
        <div className={`!bg-[#FCF1A8] w-full`}>
          <Marquee
            gradient={false}
            speed={50}
          >
            <div className={`text-[#1A2C39] text-[28px] lg:text-[60px] leading-none lg:leading-tight flex items-center pt-3 lg:pt-2 pb-4 lg:pb-4 whitespace-nowrap`}>
              <div>&nbsp;ECCO Leather Goods – SS22&nbsp;</div>
              <Hand className={`w-[25px] lg:w-[38px] mx-2 lg:mx-4 fill-[#1A2C39]`} />
              <div>&nbsp;ECCO Leather Goods – SS22 –</div>
            </div>
          </Marquee>
        </div>
        <div className={`w-full relative`}>
          <div className={`lg:hidden text-[#1A2C39] w-full pb-0 px-6`}>
            <div className={`text-[22px] leading-tight pt-16 pb-2`}>
              {text2}
            </div>
          </div>
          <div className={`relative z-10 w-full`}>
            <PeopleList people={people} setVideoPerson={setVideoPerson} />
          </div>
        </div>
      </div>
      {videoPerson && <PersonFullVideo person={videoPerson} onClose={onCloseFullVideo} />}
    </Layout>
  )
}

export default Collage

export const data = graphql`
  query AllCollage {
    allCollageJson {
      nodes {
        id
        name
        position
        description
        assets {
          src {
            childImageSharp {
              gatsbyImageData
            }
            extension
          }
          video
        }
      }
    }
  }
`

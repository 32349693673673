import React, { useEffect, useState } from 'react'
import constants from '../constants';

const useDeviceVersion = () => {
  const [version, setVersion] = useState();
  useEffect(() => {
    const onResize = () => {
      if (typeof window !== 'undefined') {
        if (window.innerWidth < constants.MQ_LARGE) {
          if (version !== constants.MOBILE) setVersion(constants.MOBILE);
        } else if (version !== constants.DESKTOP) setVersion(constants.DESKTOP);
      }
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  return version;
};
export default useDeviceVersion